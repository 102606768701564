<template>
    <div class="wrapper1">
        <transition name="fade-transform"
                    mode="out-in">
            <router-view v-if="isRouterAlive"></router-view>
        </transition>
    </div>
</template>

<script>
    export default {
        provide(){
            return {
                reload:this.reload
            }
        },
        components: {},
        props: {},
        data() {
            return {
                isRouterAlive:true
            };
        },
        watch: {},
        computed: {},
        methods: {
            //刷新
            reload(){
                this.isRouterAlive=false
                this.$nextTick(function(){
                    this.isRouterAlive=true
                })
            }
        },
        created() {},
        mounted() {}
    };
</script>
<style lang="less" scoped>
    .wrapper1{
        width: 100%;
        height:100%;
        font-size: 12px;
    }
</style>
